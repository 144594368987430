export const SET_MIN_CAPACITY = "SET_MIN_CAPACITY";
export const TOGGLE_DISPLAY_VIDEO_ACQUISITION_ROOMS =
  "TOGGLE_DISPLAY_VIDEO_ACQUISITION_ROOMS";
export const TOGGLE_DISPLAY_VIDEO_MEETING_ROOMS =
  "TOGGLE_DISPLAY_VIDEO_MEETING_ROOMS";
export const TOGGLE_DISPLAY_UNAVAILABLE_ROOMS =
  "TOGGLE_DISPLAY_UNAVAILABLE_ROOMS";
export const TOGGLE_DISPLAY_OPEN_SPACES = "TOGGLE_DISPLAY_OPEN_SPACES";
export const TOGGLE_DISPLAY_CAMPUS_AMONG = "TOGGLE_DISPLAY_CAMPUS_AMONG";
export const TOGGLE_DISPLAY_BUILDING_AMONG = "TOGGLE_DISPLAY_BUILDING_AMONG";

export const SELECT_ROOM_TYPE = "SELECT_ROOM_TYPE";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
