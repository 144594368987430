// lib
import React, { useCallback } from 'react';
import Dimensions from 'react-dimensions';
import PropTypes from 'prop-types';
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import { isValid, isAfter, isEqual, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
// src
import theme from './DatePickerTheme';

const CALENDAR_HEIGHT = 300;

const locale = {
  blank: 'Sélectionnez une date :',
  headerFormat: 'dddd, D MMM',
  locale: frLocale,
  todayLabel: {
    long: "Aujourd'hui",
    short: 'Auj.',
  },
  weekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  weekStartsOn: 1, // Start the week on Monday
};

const createDateObject = (dateString) => {
  const [day, month, year] = dateString
    .split('/')
    .map((part) => parseInt(part, 10));
  return new Date(year, month - 1, day);
};

const InputField = ({ inputRef, initialValue, minDate, updateDateSelect }) => {
  const handleValidationDate = useCallback(() => {
    const inputValue = inputRef.current.value;
    const dateObject = createDateObject(inputValue);

    if (!isValid(dateObject)) {
      alert('Date invalide. Veuillez entrer une date au format DD/MM/YYYY.');
      return;
    }

    if (!isAfter(dateObject, minDate) && !isEqual(dateObject, minDate)) {
      alert(
        `La date doit être supérieure ou égale à ${format(
          minDate,
          'DD/MM/YYYY',
        )}.`,
      );
      return;
    }
    updateDateSelect(dateObject);
  }, [inputRef, minDate, updateDateSelect]);

  return (
    <div className="row justify-content-center">
      <div className="input-group mt-1 mb-1 col-8">
        <input
          className="form-control"
          type="text"
          ref={inputRef}
          defaultValue={initialValue}
          placeholder="DD/MM/YYYY"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleValidationDate();
            }
          }}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-primary custom-btn-cs btn-sm"
            onClick={handleValidationDate}
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  );
};

class DatePicker extends React.PureComponent {
  inputRef = React.createRef();

  render() {
    const today = new Date().setHours(0, 0, 0, 0);
    const {
      containerWidth = CALENDAR_HEIGHT,
      handleDateSelect,
      selectedDate,
    } = this.props;
    const updateDateSelect = (date) => {
      this.inputRef.current.value = date.toLocaleDateString('fr-FR');
      handleDateSelect(date);
    };
    return (
      <>
        <InfiniteCalendar
          width={containerWidth}
          height={CALENDAR_HEIGHT}
          selected={selectedDate}
          minDate={today}
          locale={locale}
          theme={theme}
          onSelect={updateDateSelect}
        />
        <InputField
          inputRef={this.inputRef}
          initialValue={selectedDate.toLocaleDateString('fr-FR')}
          minDate={today}
          updateDateSelect={updateDateSelect}
        />
      </>
    );
  }
}
DatePicker.propTypes = {
  containerWidth: PropTypes.number.isRequired,
  handleDateSelect: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

InputField.propTypes = {
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
  initialValue: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  updateDateSelect: PropTypes.func.isRequired,
};
export default Dimensions()(DatePicker);
