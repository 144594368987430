export const SELECT_ROOM_TO_BOOK = 'SELECT_ROOM_TO_BOOK';
export const SET_EVENT_NAME = 'SET_EVENT_NAME';
export const SET_FOR_USER_NAME = 'SET_FOR_USER_NAME';
export const SET_VIDEO_PROVIDER = 'SET_VIDEO_PROVIDER';
export const ATTEMPT_BOOK_CONFIRM = 'ATTEMPT_BOOK_CONFIRM';
export const REQUEST_BOOK = 'REQUEST_BOOK';
export const RECEIVE_BOOK_CONFIRMATION = 'RECEIVE_BOOK_CONFIRMATION';
export const RECEIVE_BOOK_UNKNOWN_ERROR = 'RECEIVE_BOOK_UNKNOWN_ERROR';
export const RECEIVE_ROOM_ALREADY_BOOKED_ERROR = 'RECEIVE_ROOM_ALREADY_BOOKED_ERROR';
export const RECEIVE_FAILED_BECAUSE_MISSING_EMAIL_ERROR = 'RECEIVE_FAILED_BECAUSE_MISSING_EMAIL_ERROR';
export const RECEIVE_FAILED_BECAUSE_NEED_PERMISSION = 'RECEIVE_FAILED_BECAUSE_NEED_PERMISSION';
