export const INITIALIZE_MODIF_MODAL = 'INITIALIZE_MODIF_MODAL';
export const SET_NAME_OF_MODIFIED_EVENT = 'SET_NAME_OF_MODIFIED_EVENT';
export const SET_START_HOUR_OF_MODIFIED_EVENT = 'SET_START_HOUR_OF_MODIFIED_EVENT';
export const SET_START_MINUTES_OF_MODIFIED_EVENT = 'SET_START_MINUTES_OF_MODIFIED_EVENT';
export const SET_END_HOUR_OF_MODIFIED_EVENT = 'SET_END_HOUR_OF_MODIFIED_EVENT';
export const SET_END_MINUTES_OF_MODIFIED_EVENT = 'SET_END_MINUTES_OF_MODIFIED_EVENT';
export const SET_ROOM_ID_OF_MODIFIED_EVENT = 'SET_ROOM_ID_OF_MODIFIED_EVENT';
export const ATTEMPT_MODIF_CONFIRM = 'ATTEMPT_MODIF_CONFIRM';
export const REQUEST_MODIF = 'REQUEST_MODIF';
export const RECEIVE_MODIF_CONFIRMATION = 'RECEIVE_MODIF_CONFIRMATION';
export const RECEIVE_MODIF_UNKNOWN_ERROR = 'RECEIVE_MODIF_UNKNOWN_ERROR';
export const RECEIVE_MODIF_ALREADY_BOOKED_ERROR = 'RECEIVE_MODIF_ALREADY_BOOKED_ERROR';
