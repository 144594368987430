// lib
import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

// src
import { CAMPUSES, BUILDINGS } from 'config';
import Checkbox from 'components/partials/Checkbox';
import roomTypes from 'reducers/search/roomTypes.data';
import SearchBar from './SearchBar';
import TypeSelector from './TypeSelector';
import CapacitySelector from './CapacitySelector';

class Filters extends React.Component {
  // As the minCapacity and searchText values aren't set
  // anywhere else in the code, the state here doesn't
  // follow the updates of the props:
  // state => props but not props => state

  throttledSetMinCapacity = throttle(this.props.setMinCapacity, 300);

  throttledSetSearchText = throttle(this.props.setSearchText, 300);

  constructor(props) {
    super(props);
    const { minCapacity, searchText } = props;
    this.state = { minCapacity, searchText };
  }

  componentDidMount() {
    this.loadFavoritesFromCookies();
  }

  loadFavoritesFromCookies = () => {
    const {
      toggleDisplayCampusAmong,
      toggleDisplayBuildingAmong,
      toggleDisplayVideoConferenceRooms,
      toggleDisplayVideoAcquisitionRooms,
      toggleDisplayUnavailableRooms,
      toggleDisplayOpenSpaces,
      selectRoomType,
    } = this.props;

    const cookieActions = [
      {
        cookieName: 'favoriteCampuses',
        action: toggleDisplayCampusAmong,
        isArray: true,
      },
      {
        cookieName: 'favoriteBuildings',
        action: toggleDisplayBuildingAmong,
        isArray: true,
      },
      {
        cookieName: 'displayVideoConferenceRooms',
        action: () => toggleDisplayVideoConferenceRooms(true),
        isBoolean: true,
      },
      {
        cookieName: 'displayVideoAcquisitionRooms',
        action: () => toggleDisplayVideoAcquisitionRooms(true),
        isBoolean: true,
      },
      {
        cookieName: 'displayUnavailableRooms',
        action: () => toggleDisplayUnavailableRooms(true),
        isBoolean: true,
      },
      {
        cookieName: 'displayOpenSpaces',
        action: () => toggleDisplayOpenSpaces(true),
        isBoolean: true,
      },
      { cookieName: 'selectedRoomType', action: selectRoomType },
    ];

    cookieActions.forEach(({ cookieName, action, isArray, isBoolean }) => {
      let favorite = this.getFavorite(cookieName);
      if (cookieName === 'selectedRoomType' && favorite.length === 0) {
        favorite = roomTypes.options[0];
      }
      if (isArray && Array.isArray(favorite)) {
        favorite.forEach((item) => action(item));
      } else if (isBoolean && favorite === true) {
        action();
      } else if (!isArray && !isBoolean && favorite) {
        action(favorite);
      }
    });
  };

  getFavorite = (cookieName) => {
    try {
      const cookieValue = Cookies.get(cookieName) || '[]';
      return JSON.parse(cookieValue);
    } catch (e) {
      console.error('Erreur lors de la récupération du Cookie:', e);
      return [];
    }
  };

  addFavorite = (item, cookieName, toggleDisplayFunction) => {
    const favorites = this.getFavorite(cookieName);
    favorites.push(item);
    Cookies.set(cookieName, JSON.stringify(favorites));
    toggleDisplayFunction(item);
  };

  setFavorite = (item, cookieName, toggleDisplayFunction) => {
    Cookies.set(cookieName, JSON.stringify(item));
    toggleDisplayFunction(item);
  };

  selectCampus = (campus) => {
    const { toggleDisplayCampusAmong } = this.props;
    this.addFavorite(campus, 'favoriteCampuses', toggleDisplayCampusAmong);
  };

  selectBuilding = (building) => {
    const { toggleDisplayBuildingAmong } = this.props;
    this.addFavorite(building, 'favoriteBuildings', toggleDisplayBuildingAmong);
  };

  handleToggleDisplayVideoConferenceRooms = (event) => {
    const { toggleDisplayVideoConferenceRooms } = this.props;

    toggleDisplayVideoConferenceRooms(event.target.checked);
    Cookies.set('displayVideoConferenceRooms', event.target.checked);
  };

  handleToggleDisplayVideoAcquisitionRooms = (event) => {
    const { toggleDisplayVideoAcquisitionRooms } = this.props;

    toggleDisplayVideoAcquisitionRooms(event.target.checked);
    Cookies.set('displayVideoAcquisitionRooms', event.target.checked);
  };

  handleToggleDisplayUnavailableRooms = (event) => {
    const { toggleDisplayUnavailableRooms } = this.props;

    toggleDisplayUnavailableRooms(event.target.checked);
    Cookies.set('displayUnavailableRooms', event.target.checked);
  };

  handleToggleDisplayOpenSpaces = (event) => {
    const { toggleDisplayOpenSpaces } = this.props;

    toggleDisplayOpenSpaces(event.target.checked);
    Cookies.set('displayOpenSpaces', event.target.checked);
  };

  handleSelectRoomType = (type) => {
    const { selectRoomType } = this.props;

    // Sauvegardez le type sélectionné dans un cookie
    this.setFavorite(type, 'selectedRoomType', selectRoomType);
  };

  setMinCapacity = ({ x }) => {
    this.setState({ minCapacity: x });
    this.throttledSetMinCapacity({ x });
  };

  setSearchText = (searchText) => {
    this.setState({ searchText });
    this.throttledSetSearchText(searchText);
  };

  render() {
    const { searchText, minCapacity } = this.state;

    const {
      type,
      displayVideoAcquisitionRooms,
      displayVideoConferenceRooms,
      displayUnavailableRooms,
      displayOpenSpaces,
      displayCampuses,
      displayBuildings,
    } = this.props;

    return (
      <div className="sticky-top custom-sticky">
        <SearchBar
          searchText={searchText}
          onSearchTextInput={this.setSearchText}
        />
        <div className="card my-3">
          <div className="card-body">
            <TypeSelector
              disabled={Boolean(searchText)}
              onChange={this.handleSelectRoomType} // Utilisez la nouvelle méthode ici
              data={roomTypes}
              selectedType={type}
            />
            <CapacitySelector
              onChange={this.setMinCapacity}
              minCapacity={minCapacity}
            />
            <div>Campus :</div>
            {CAMPUSES.map((c) => (
              <Checkbox
                key={c}
                onChange={() => this.selectCampus(c)}
                checked={displayCampuses.includes(c)}
                name={c}
              />
            ))}
            {BUILDINGS.map((b) => (
              <Checkbox
                key={b}
                onChange={() => this.selectBuilding(b)}
                checked={displayBuildings.includes(b)}
                name={b}
              />
            ))}
            <div className="mt-1">Filtres :</div>
            <Checkbox
              onChange={this.handleToggleDisplayVideoConferenceRooms}
              checked={displayVideoConferenceRooms}
              name="Visioconférence"
            />
            <Checkbox
              onChange={this.handleToggleDisplayVideoAcquisitionRooms}
              checked={displayVideoAcquisitionRooms}
              name="Captation vidéo"
            />
            <div className="mt-1">Compléments :</div>
            <Checkbox
              onChange={this.handleToggleDisplayUnavailableRooms}
              checked={displayUnavailableRooms}
              name="Afficher les salles indisponibles"
            />
            <Checkbox
              onChange={this.handleToggleDisplayOpenSpaces}
              checked={displayOpenSpaces}
              name="Afficher les espaces ouverts"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favoriteCampuses: state.bookings.list.favoriteCampuses,
  favoriteBuildings: state.bookings.list.favoriteBuildings,
});
Filters.propTypes = {
  minCapacity: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired,
  setMinCapacity: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  selectRoomType: PropTypes.func.isRequired,
  type: PropTypes.object.isRequired,
  toggleDisplayVideoAcquisitionRooms: PropTypes.func.isRequired,
  toggleDisplayVideoConferenceRooms: PropTypes.func.isRequired,
  toggleDisplayUnavailableRooms: PropTypes.func.isRequired,
  toggleDisplayOpenSpaces: PropTypes.func.isRequired,
  toggleDisplayCampusAmong: PropTypes.func.isRequired,
  toggleDisplayBuildingAmong: PropTypes.func.isRequired,
  displayVideoAcquisitionRooms: PropTypes.bool.isRequired,
  displayVideoConferenceRooms: PropTypes.bool.isRequired,
  displayUnavailableRooms: PropTypes.bool.isRequired,
  displayOpenSpaces: PropTypes.bool.isRequired,
  displayCampuses: PropTypes.array.isRequired,
  displayBuildings: PropTypes.array.isRequired,
};
export default connect(mapStateToProps)(Filters);
